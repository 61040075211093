#contact1 {
    padding: 5px 0 60px;
    background: linear-gradient(to right, #ff4b4b 0%, #d86b6b 100%);
    color: rgba(255, 255, 255, 0.75);
    
  }
  #contact1 .section-title {
    margin-bottom: 40px;
  }
  #contact1 .section-title p {
    font-size: 16px;
  }
  #contact1 h2 {
    color: #fff;
    margin-top: 130px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  #contact1 .section-title h2::after {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 30px;
  }
  #contact1 h3 {
    color: #fff;
    margin-top: 80px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    font-weight: 400;
  }
  #contact1 form {
    padding-top: 20px;
  }
  #contact1 .text-danger {
    color: #cc0033;
    text-align: left;
  }
  #contact1 .btn-custom {
    margin: 30px 0;
    background: transparent;
    border: 2px solid #fff;
  }
  #contact1 .btn-custom:hover {
    color: #ff7676;
    background: #fff;
  }
  .contact_button :hover
  {
    transform: scale(1.2);
  }
  label {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    float: left;
  }
  #contact1 .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  
  #contact1 .form-control:focus {
    border-color: #999;
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
  }
  .form-control::-webkit-input-placeholder {
    color: #777;
  }
  .form-control:-moz-placeholder {
    color: #777;
  }
  .form-control::-moz-placeholder {
    color: #777;
  }
  .form-control:-ms-input-placeholder {
    color: #777;
  }
  #contact1 .contact-item {
    margin: 20px 0;
  }
  #contact1 .contact-item span {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    display: block;
  }
  #contact1 .contact-item i.fa {
    margin-right: 10px;
  }
  
  
  .footerNav{
    margin-bottom: 40px;
  }
  .footerNav ul{
    display: flex;
    justify-content: center;
    list-style-type: none;
  }
  .footerNav ul li a{
    color:rgb(255, 255, 255);
    margin: 10px;
    text-decoration: none;
    font-size: 1.11em;
    opacity: 0.8;
    transition: 0.2s;
  
  }
  .footerNav ul li a:hover{
    opacity: 5;
  
  }
  
  #contact1 .social1 {
    /* border-top: 1px solid rgba(253, 253, 253, 0.332); */
    /* padding-top: 50px; */
    margin-top: 50px;
    text-align: center;
  }

  #contact1 .social {
    /* border-top: 1px solid rgba(253, 253, 253, 0.332); */
    /* padding-top: 50px; */
    margin-top: 50px;
    text-align: center;
  }
  #contact1 .social ul li {
    display: inline-block;
    margin: 0 20px;
    margin-top: 20px;
  }
  #contact1 .social i.fa {
    font-size: 22px;
    width: 48px;
    height: 48px;
    display: block;
    padding: 12px 0;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s;
  }
  #contact1 .social i.fa:hover {
    color: #ff4b4b;
    background: #fff;
  }
  #footer p {
    /* margin-top: 0px;
    margin-bottom: 0px; */
    color: #888;
    font-size: 14px;
  }