.row1
{
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  
}
.main_div_c
{
    position: relative;
    top: 100px;
}

#team2 {
  padding: 100px 0;
}
#team2 {
  background-color: #fff;
}
#team2 h4 {
  margin: 5px 0;
}
.team2-img {
  width: 240px;
}
.thumbnail1 {
  background: transparent;
  border: 0;
}
.thumbnail1 img {
  border-radius: 200%;
  object-fit: cover;
  width: 100px;
  height: auto;
}

.thumbnail1
{
    padding: 10px 0 0;
    color: #888;
  }
.caption1 {
  padding: 10px 0 0;
  color: #000000;
  font-size: 18;
}


.footerNav1 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.footerNav1 ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.footerNav1 ul li a {
  color: rgb(26, 23, 23);
  margin: 10px;
  text-decoration: none;
  font-size: 1.11em;
  opacity: 0.8;
  transition: 0.2s;
}

.footerNav ul li ab:hover {
  opacity: 1;
}
.social1 {
  background-color: #ff7676;
  border-top: 1px solid rgba(12, 0, 0, 0.332);
  padding-top: 50px;
  padding-bottom: 40px;
  /* margin-top: 50px; */
  text-align: center;
}



