.main_div1
{
    position: relative;
    top: 300px;
}
.main_div1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}

.heading-large {
  font-size: 3rem; /* Adjust the font size as needed */
}

.heading-large2 {
    font-size: 2.5rem; /* Adjust the font size as needed */
  }

.paragraph-large {
  font-size: 1.2em; /* Adjust the value as per your requirement */
  color: #5c5c5c;
}

.content {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .content.active {
    opacity: 1;
    transform: translateY(0);
  }
  
  .underline-on-hover {
    color: blue;
    cursor: pointer;
    text-decoration: none;
}

.underline-on-hover:hover {
    text-decoration: underline;
}